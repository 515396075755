<template>
  <div class="app-container set-score">
    <div class="tips">注意：该省份已公布高考成绩，请设置您的高考成绩；一经确认，无法再次修改，请仔细核对！</div>
    <div class="title">设置高考成绩</div>
    <div class="box">
      <el-form :model="query" :rules="rule" ref="scoreForm" label-position="right" label-width="120px">
        <el-form-item label="高考成绩" prop="realScore">
          <el-input v-model="query.realScore"
            oninput="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            maxlength="3" placeholder="请输入高考成绩"></el-input>
        </el-form-item>
        <el-form-item label="确认高考成绩" prop="checkRealScore">
          <el-input v-model="query.checkRealScore"
            oninput="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            maxlength="3" placeholder="请确认高考分数"></el-input>
        </el-form-item>
        <div class="submit-box">
          <el-button class="submit" @click="verifyScore" type="primary">确 认</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
import { validateRealScore } from '@/utils/utils'
export default {
  name: "realScore-index",
  data() {
    return {
      query: {
        realScore: '',
        checkRealScore: '',
      },

      rule: {
        realScore: [
          { required: true, message: '请输入高考成绩！', trigger: ['change', 'blur'] },
          { required: true, validator: validateRealScore, trigger: ['change', 'blur'] }
        ],
        checkRealScore: [
          { required: true, message: '请再次输入高考成绩！', trigger: ['change', 'blur'] },
          { required: true, validator: validateRealScore, trigger: ['change', 'blur'] },
          {
            required: true, validator: (rule, value, callback) => {
              if (value !== this.query.realScore) {
                callback(new Error('两次输入的分数不一致，请重新输入！'))
              } else callback()
            }, trigger: ['change', 'blur']
          },
        ]
      }
    }
  },
  mounted() { },
  methods: {
    //verify real score and submit that
    verifyScore() {
      this.$refs.scoreForm.validate(valid => {
        if (valid) {
          this.$confirmTips('<div style="color: #F56C6C;">您输入的分数为：' + this.query.realScore + '</div>一经确认，无法再次修改，确认提交?', '注意！', 'warning').then(_ => {
            this.submitScore()
          }).catch(err => { })
        }
      })
    },
    //submit score
    submitScore() {
      API.User.UpdateUserExamFinalScore({
        Score: this.query.realScore
      }).then(res => {
        if (res.code !== 0) {
          this.$message.error(res.message)
        } else {
          this.$store.dispatch('user/getStudentInfo').then(_ => {
            if (this.$route.query.source === 'TB') {
              this.$store.dispatch('user/judgeInfoComplete').then(() => {

                this.$message.success('设置成功！')
                setTimeout(() => {
                  this.$router.push('/')
                }, 300)
              })
              return
            }
          })
        }
      }).catch(err => {
        this.$message.error(err.message)
      })
    }
  }
}
</script>

<style scoped lang="less">
.set-score {
  padding: 5rem 15rem;

  .tips {
    color: #f56c6c;
    padding: 2rem 0;
  }

  .title {
    padding: 2rem;
    font-size: 24px;
    color: #515357;
    text-align: center;
  }

  .box {

    .submit-box {
      margin-top: 3rem;
      text-align: center;

      .submit {
        text-align: center;
        width: 8rem;
        letter-spacing: 2px;
      }
    }

  }
}
</style>
